exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-analizando-tsx": () => import("./../../../src/pages/analizando.tsx" /* webpackChunkName: "component---src-pages-analizando-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-iniciar-sesion-tsx": () => import("./../../../src/pages/iniciar-sesion.tsx" /* webpackChunkName: "component---src-pages-iniciar-sesion-tsx" */),
  "component---src-pages-interpretacion-tsx": () => import("./../../../src/pages/interpretacion.tsx" /* webpackChunkName: "component---src-pages-interpretacion-tsx" */),
  "component---src-pages-mis-suenos-tsx": () => import("./../../../src/pages/mis-suenos.tsx" /* webpackChunkName: "component---src-pages-mis-suenos-tsx" */),
  "component---src-pages-pregunta-tsx": () => import("./../../../src/pages/pregunta.tsx" /* webpackChunkName: "component---src-pages-pregunta-tsx" */),
  "component---src-pages-registro-tsx": () => import("./../../../src/pages/registro.tsx" /* webpackChunkName: "component---src-pages-registro-tsx" */),
  "component---src-pages-respondiendo-tsx": () => import("./../../../src/pages/respondiendo.tsx" /* webpackChunkName: "component---src-pages-respondiendo-tsx" */),
  "component---src-pages-sobre-nosotros-tsx": () => import("./../../../src/pages/sobre-nosotros.tsx" /* webpackChunkName: "component---src-pages-sobre-nosotros-tsx" */),
  "component---src-pages-sorteos-tsx": () => import("./../../../src/pages/sorteos.tsx" /* webpackChunkName: "component---src-pages-sorteos-tsx" */),
  "component---src-pages-suenos-tsx": () => import("./../../../src/pages/suenos.tsx" /* webpackChunkName: "component---src-pages-suenos-tsx" */),
  "component---src-pages-terminos-y-condiciones-tsx": () => import("./../../../src/pages/terminos-y-condiciones.tsx" /* webpackChunkName: "component---src-pages-terminos-y-condiciones-tsx" */)
}

